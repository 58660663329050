import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import clinikoSVG from 'images/clinikoSVG.svg';
import nookalSVG from 'images/nookal-logo.png';
import arrow from 'images/arrow.svg';
import defaultHero from 'images/physiotherapist.jpg';

import { ClinicDirectus } from 'apiFolder/directus-main';

import Seo from '../components/seo';
import Header from 'components/common/header/Header';
import Footer from '../components/Footer/Footer';
import { Content } from 'components/Content';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import ThemeProviderComponent from 'components/common/ThemeProvider';

interface Context {
	pageContext: {
		item: ClinicDirectus;
		dataSuburbSlugs: string[];
	};
}

const clinicTemplate: React.FC<Context> = ({ pageContext: { item, dataSuburbSlugs } }) => {
	const [state, setState] = useState('Suburbs serviced');
	const [NavWords, setNavWords] = useState([
		{ isActive: true, name: 'Suburbs serviced' },
		{ isActive: false, name: `About ${item.name}` },
		{ isActive: false, name: 'Nearby Clinics' },
	]);
	const [isMenuOpen, toggleMenu] = useState(false);

	const heroImage = item.images_data?.team_image
		? `${item.images_data.team_image?.replace('$1', '?')}?key=large`
		: null;

	const logoImage = item.images_data?.logo ? item.images_data.logo : null;

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	const nearest =
		item.nearby_clinics
			?.filter((clinic) => clinic.link !== item.slug)
			.sort((a, b) => a.txt.localeCompare(b.txt))
			.map((clinic) => {
				const clinicName = clinic.txt
					.replaceAll('&', 'and')
					.replaceAll('+ ', '')
					.toLowerCase();
				const clinicSlugName = clinic.link
					.replace('/clinic/', '')
					.replaceAll('-', ' ')
					.toLowerCase();
				const sameNameClinicSuburb = clinicSlugName.replace(clinicName, '').replace(' ', '');

				return {
					...clinic,
					suburbName: sameNameClinicSuburb,
				};
			}) || [];

	const onNavChange = (word: string) => {
		setState(word);
		setNavWords(
			NavWords?.map((m) => {
				m.isActive = m.name === word;
				return m;
			}),
		);
	};

	return (
		<>
			<Seo title={item.meta_title} description={item.meta_description} markupSchema={item.markup_schema} />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<TopBlock>
						<Content isOpen={isMenuOpen}>
							<TopBlockTitle>
								<TopBlockH5>Hey,</TopBlockH5>
								<TopBlockH1>{item.name}</TopBlockH1>
								<TopBlockH5>let's work together to accelerate the success of your clinic</TopBlockH5>
							</TopBlockTitle>
							<TopBlockParagraph>
								We're Bookphysio.com, a physiotherapy specific booking site, and we'd love to help you
								fill any gaps in your diary.
							</TopBlockParagraph>
							<RegisterWrapper>
								<a href={item.typeform_registration_link} target='_blank' rel='noopener noreferrer'>
									<Button>Register your clinic - it's free</Button>
								</a>
								<span>It takes just 60 seconds</span>
							</RegisterWrapper>
						</Content>
					</TopBlock>
					<Content>
						<ClinikoWrapper>
							<ArrowWrapper>
								<img src={arrow} alt='arrow' width='45px' />
							</ArrowWrapper>
							<Cliniko>
								<ClinikoLeftWrapper>
									<SvgWrapper>
										{item.provider === 'Cliniko' ? (
											<img src={clinikoSVG} alt='' width='150px' />
										) : (
											<img src={nookalSVG} alt='' width='150px' />
										)}
									</SvgWrapper>
									<ClinikoParagraph>
										<div>
											<ClinikoParagraphSpan>
												{item.provider === 'Cliniko' ? 'Cliniko' : 'Nookal'} user?
											</ClinikoParagraphSpan>{' '}
											We’ve got a special deal for you.
										</div>
										<p>Get setup in under 60 seconds.</p>
									</ClinikoParagraph>
								</ClinikoLeftWrapper>

								<a href={item.typeform_registration_link} target='_blank' rel='noopener noreferrer'>
									<ClinikoButton>
										Connect via {item.provider === 'Cliniko' ? 'Cliniko' : 'Nookal'}
									</ClinikoButton>
								</a>
							</Cliniko>
						</ClinikoWrapper>
					</Content>
					<PhysiotherapistStyled>
						<Content>
							<PhysiotherapistInner>
								<ImgBlock>
									<img
										src={heroImage || defaultHero}
										alt={
											item.images_data?.team_image_alt_text
												? item.images_data.team_image_alt_text
												: 'hero'
										}
									/>
								</ImgBlock>
								<PhysiotherapistRight>
									<H2>{`Increase your visibility & book more patients 24/7`}</H2>
									<Paragraphs>
										<p>
											BookPhysio.com increases the viability of your clinic and puts you in-front
											of more patients looking to book a physio appointments.
										</p>
									</Paragraphs>
									<List>
										<Item>
											<svg
												stroke='currentColor'
												fill='currentColor'
												strokeWidth='0'
												version='1'
												viewBox='0 0 48 48'
												enableBackground='new 0 0 48 48'
												height='1em'
												width='1em'
												xmlns='http://www.w3.org/2000/svg'
											>
												<polygon
													fill='#79d5b8'
													points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
												/>
											</svg>
											<LiText>Zero risk</LiText>
										</Item>
										<Item>
											<svg
												stroke='currentColor'
												fill='currentColor'
												strokeWidth='0'
												version='1'
												viewBox='0 0 48 48'
												enableBackground='new 0 0 48 48'
												height='1em'
												width='1em'
												xmlns='http://www.w3.org/2000/svg'
											>
												<polygon
													fill='#79d5b8'
													points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
												/>
											</svg>
											<LiText>Zero effort</LiText>
										</Item>
										<Item>
											<svg
												stroke='currentColor'
												fill='currentColor'
												strokeWidth='0'
												version='1'
												viewBox='0 0 48 48'
												enableBackground='new 0 0 48 48'
												height='1em'
												width='1em'
												xmlns='http://www.w3.org/2000/svg'
											>
												<polygon
													fill='#79d5b8'
													points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
												/>
											</svg>

											<LiText>Zero joining fees</LiText>
										</Item>
										<Item>
											<svg
												stroke='currentColor'
												fill='currentColor'
												strokeWidth='0'
												version='1'
												viewBox='0 0 48 48'
												enableBackground='new 0 0 48 48'
												height='1em'
												width='1em'
												xmlns='http://www.w3.org/2000/svg'
											>
												<polygon
													fill='#79d5b8'
													points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
												/>
											</svg>
											<LiText>Zero ongoing fees</LiText>
										</Item>
									</List>
								</PhysiotherapistRight>
							</PhysiotherapistInner>
						</Content>
					</PhysiotherapistStyled>
					<Content>
						<Connect>
							<Line />
							<ConnectH3>Connect with new patient in 3 simple steps</ConnectH3>
							<Line />
						</Connect>
						<Steps>
							<Step>
								<StepHeading>Step 1.</StepHeading>
								<StepSubHeading>Register your clinic</StepSubHeading>
								<StepContent>Register & connect your PMS diary in under 60 seconds</StepContent>
							</Step>
							<Step>
								<StepHeading>Step 2.</StepHeading>
								<StepSubHeading>We’ll create your profile</StepSubHeading>
								<StepContent>
									Your availabilities will be displayed on your profile & our location pages
								</StepContent>
							</Step>
							<Step>
								<StepHeading>Step 3. </StepHeading>
								<StepSubHeading>Book new patients 24/7</StepSubHeading>
								<StepContent>Patients make bookings & your clinic grows</StepContent>
							</Step>
						</Steps>
						<RequestBlock>
							<RequestBlockPhoto>
								<StaticImage src='../images/Matt.jpeg' alt='Matt' width={100} height={100} />
							</RequestBlockPhoto>
							<RequestRight>
								<RequestBlockH4>Curious, but still a little unsure?</RequestBlockH4>
								<RequestBlockContent>
									Setup a call with one of our clinic onboarding specialists, where we’ll discuss our
									features and benefits. We can answer any questions you may have!
								</RequestBlockContent>
								<a href='https://calendly.com/book-physio/clinic-discovery-call?month=2022-01'>
									<RequestButton>Request a callback</RequestButton>
								</a>
							</RequestRight>
						</RequestBlock>

						<AboutWrapper>
							<Navigation>
								<NavigationItems>
									{NavWords?.map((word) => (
										<NavItem key={word.name} onClick={() => onNavChange(word.name)}>
											<NavItemText isActive={word.isActive}>{word.name}</NavItemText>
										</NavItem>
									))}
								</NavigationItems>
								<NavigationBorder />
							</Navigation>
							<PopularLocations>
								<Info isOpen={state === 'Suburbs serviced'}>
									{item.nearby_suburbs?.map((suburb) =>
										dataSuburbSlugs.includes(suburb.link) ? (
											<AboutClinicMark key={suburb.link}>
												<Link to={suburb.link}>{suburb?.txt}</Link>
											</AboutClinicMark>
										) : (
											<AboutClinicMark key={suburb.txt}>
												<Plain>{suburb?.txt}</Plain>
											</AboutClinicMark>
										),
									)}
								</Info>
								<Info isOpen={state === `About ${item.name}`}>
									<AboutClinic>
										<AboutClinicName>{item.name}</AboutClinicName>
										<AboutClinicContent>{item.about_clinic}</AboutClinicContent>
										<AboutClinicMark>
											Website: <a href={item.website}>{item.website}</a>
										</AboutClinicMark>
										<AboutClinicMark>
											Address: <span style={{ fontWeight: '300' }}>{item.full_address}</span>
										</AboutClinicMark>
										{item.suburb && (
											<AboutClinicMark>
												Associated suburb:{' '}
												<Link to={item.suburb?.link}>{item.suburb?.text}</Link>
											</AboutClinicMark>
										)}
										{item.city && (
											<AboutClinicMark>
												Associated city: <Link to={item.city?.link}>{item.city?.text}</Link>
											</AboutClinicMark>
										)}
										{item.state && (
											<AboutClinicMark>
												Associated state: <Link to={item.state?.link}>{item.state?.text}</Link>
											</AboutClinicMark>
										)}
										{logoImage && (
											<AboutClinicMark>
												<img src={logoImage} alt={item.images_data?.logo_alt_text} />
											</AboutClinicMark>
										)}
										{item.practitioners?.length > 0 && (
											<>
												<H3>Practitioners</H3>
												{item.practitioners.map((practitioner) => (
													<H4 key={practitioner.first_name}>
														{`${practitioner.first_name} ${practitioner.last_name} Physiotherapist`}
													</H4>
												))}
											</>
										)}
									</AboutClinic>
								</Info>
								<Info isOpen={state === 'Nearby Clinics'}>
									{nearest &&
										nearest.length > 0 &&
										nearest.map((clinic, index) => (
											<Suburb key={`${clinic.txt}${index}`}>
												<Link to={clinic?.link}>
													{clinic?.txt}
													{clinic.suburbName && <Span>({clinic?.suburbName})</Span>}
												</Link>
											</Suburb>
										))}
								</Info>
							</PopularLocations>
						</AboutWrapper>
					</Content>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};
export const TopBlock = styled.section`
	background-color: #f7f8fc;
	padding-bottom: 100px;
`;

export const TopBlockParagraph = styled.p`
	margin: 30px 0;
	font-weight: 300;
	width: 510px;
	font-size: 18px;
	@media (max-width: 768px) {
		margin: 30px auto;
		text-align: center;
	}
	@media (max-width: 545px) {
		width: 100%;
	}
	@media (max-width: 425px) {
		margin: 25px 0 37px;
	}
`;
export const Button = styled.button`
	background-color: #4544f5;
	padding: 20px 30px;
	color: #ffffff;
	font-weight: 500;
	font-size: 18px;
	border-radius: 4px;
	margin-right: 20px;
	cursor: pointer;
	height: 62px;
	@media (max-width: 525px) {
		width: 360px;
		margin-bottom: 25px;
	}
	@media (max-width: 525px) {
		width: 320px;
		margin: 0 auto 25px;
	}
	@media (max-width: 425px) {
		width: 100%;
	}
`;

export const PhysiotherapistStyled = styled.div`
	width: 100%;
	padding-top: 67px;
	padding-bottom: 67px;
	margin-bottom: 33px;
	@media (max-width: 768px) {
		margin-bottom: 20px;
	}
	@media (max-width: 425px) {
		padding-top: 50px;
		padding-bottom: 45px;
		margin-bottom: 40px;
	}
`;
const PhysiotherapistInner = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 800px) {
		flex-direction: column;
	}
`;
const PhysiotherapistRight = styled.div`
	flex: 0 0 45.5%;
	margin-left: 64px;
	margin-top: 40px;
	margin-bottom: 40px;
	@media (max-width: 800px) {
		flex: 0 0 100%;
		margin-left: 0;
	}
`;
export const H2 = styled.h2`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	line-height: 1.3;
	color: #152231;
	margin-top: 30px;
	font-size: 30px;
	@media (max-width: 1024px) {
		font-size: 24px;
	}
`;
export const H3 = styled.h3`
	font-family: 'MuseoSans', sans-serif;
	font-size: 24px;
	color: #0c1f3f;
	margin: 55px 0 20px;
`;
export const H4 = styled.h4`
	font-weight: 500;
	font-size: 14px;
	font-family: 'MuseoSans', sans-serif;
	margin: 5px 0;
	color: #0c1f3f;
`;
export const Paragraphs = styled.div`
	margin: 20px 0;
	font-weight: 500;
	font-size: 18px;
`;

export const List = styled.ul`
	list-style: none;
`;
export const Item = styled.li`
	padding: 5px 0;
`;

export const Img = styled.img`
	flex: 0 0 50%;
	border-radius: 6px;
	object-fit: contain;
	max-width: 100%;
	@media (max-width: 800px) {
		flex: 0 0 100%;
		width: 100%;
	}
`;

export const LiText = styled.span`
	margin-left: 5px;
	font-weight: 300;
	font-size: 16px;
`;

export const Cliniko = styled.div`
	background-color: #cbf2f9;
	border: 2px solid #33b9da;
	border-radius: 4px;
	display: flex;
	padding: 25px 30px;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 768px) {
		flex-direction: column;
	}
	@media (max-width: 425px) {
		padding: 30px 18px 40px;
	}
`;
export const SvgWrapper = styled.div`
	border-right: 2px solid #33b9da;
	padding: 10px 30px 10px 0;
	margin-right: 30px;
	width: 182px;
	@media (max-width: 768px) {
		border-right: none;
		padding: 0;
		margin-right: 0;
		width: 100%;
		text-align: center;
	}
	@media (max-width: 620px) {
		margin-right: 0;
		padding: 10px 0;
		margin-bottom: 34.5px;
	}
	@media (max-width: 425px) {
		padding: 0;
		margin-right: 0;
		width: 100%;
		text-align: center;
	}
`;
export const ClinikoLeftWrapper = styled.div`
	display: flex;
	@media (max-width: 768px) {
		margin-bottom: 46px;
	}
	@media (max-width: 620px) {
		flex-direction: column;
		align-items: center;
	}
`;

export const ClinikoButton = styled.button`
	background-color: #4544f5;
	padding: 20px 30px;
	color: #ffffff;
	font-weight: 500;
	font-size: 18px;
	border-radius: 4px;
	cursor: pointer;
	height: 62px;
	white-space: nowrap;
	@media (max-width: 768px) {
		width: 340px;
	}
	@media (max-width: 430px) {
		width: 100%;
	}
`;
export const ClinikoParagraph = styled.div`
	font-weight: 300;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	@media (max-width: 425px) {
		align-items: center;
	}
`;
export const ClinikoParagraphSpan = styled.span`
	font-weight: 500;
`;
export const Connect = styled.div`
	display: flex;
	align-items: center;
`;
export const ConnectH3 = styled.h3`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	width: 100%;
	margin: 0 50px;
	white-space: nowrap;
	@media (max-width: 851px) {
		text-align: center;
		// padding: 0 0 40px;
		margin: 0;
		white-space: pre-wrap;
	}
	@media (max-width: 606px) {
		font-size: 20px;
	}
`;

export const Line = styled.div`
	background-color: #e1e1e1;
	width: 100%;
	height: 1px;
	@media (max-width: 851px) {
		display: none;
	}
`;
export const Steps = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 91px;
	@media (max-width: 767px) {
		flex-direction: column;
	}
	@media (max-width: 425px) {
		margin-bottom: 60px;
	}
`;
export const StepHeading = styled.div`
	color: #0035cb;
	font-size: 20px;
`;
export const StepSubHeading = styled.div`
	font-weight: 500;
	margin: 15px 0;
`;
export const StepContent = styled.div`
	font-weight: 300;
`;
export const Step = styled.div`
	flex: 0 0 32%;
	max-width: 300px;
	margin-top: 100px;
	@media (max-width: 606px) {
		margin-top: 40px;
	}
	@media (max-width: 425px) {
		&:first-child {
			margin-top: 0;
		}
	}
`;

export const RequestBlock = styled.div`
	border: 1px solid #4544ec;
	border-radius: 10px;
	background-color: #f7f8fc;
	padding: 40px 60px;
	display: flex;
	margin: 140px 0;
	@media (max-width: 600px) {
		flex-direction: column;
		align-items: center;
		margin: 50px 0;
	}
	@media (max-width: 425px) {
		padding: 30px 27px;
	}
`;
export const RequestBlockPhoto = styled.div`
	flex: 0 0 100px;
	width: 100px;
	height: 100px;
	& img {
		border-radius: 50%;
	}
	@media (max-width: 600px) {
		margin-bottom: 40px;
	}
`;
export const RequestBlockH4 = styled.h5`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
`;
export const RequestBlockContent = styled.div`
	font-weight: 300;
	margin: 15px 0;
	width: 600px;
	@media (max-width: 906px) {
		width: 100%;
		margin: 30px 0;
	}
`;
export const RequestButton = styled.button`
	padding: 15px 71px;
	color: #4544ec;
	border: 1px solid #4544ec;
	border-radius: 4px;
	font-size: 18px;
	font-weight: 300;
	cursor: pointer;
	background-color: #fff;
	text-decoration: none;
	white-space: nowrap;
	@media (max-width: 475px) {
		width: 100%;
		padding: 15px 50px;
	}
`;
export const NavItem = styled.div`
	cursor: pointer;
	font-weight: 300;
	font-size: 16px;
	text-align: center;
	border-bottom: 2px solid #c9c8ca;
	z-index: 2;
	padding: 0 45px;
`;
export const NavItemText = styled.div<{ isActive: boolean }>`
	padding: 0 5px 10px;
	white-space: nowrap;
	color: ${({ isActive }) => (isActive ? '#000' : '#9a9999')};
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 2px;
		background: ${({ isActive }) => (isActive ? '#000000' : 'none')};
		border-radius: 2px;
	}
`;
export const Navigation = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	overflow-x: auto;
`;
export const NavigationItems = styled.div`
	display: flex;
	margin: 0 -45px;
`;

export const NavigationBorder = styled.div`
	border-bottom: 2px solid #c9c8ca;
	min-width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 1;
`;

export const AboutClinic = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	font-weight: 300;
	flex-wrap: wrap;
`;

export const PopularLocations = styled.div`
	padding-top: 25px;
	display: flex;
	flex-direction: column;
	font-weight: 300;
	flex-wrap: wrap;
`;
export const Info = styled.div<{ isOpen: boolean }>`
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;
export const AboutClinicName = styled.h2`
	color: #0c1f3f;
`;
export const AboutClinicContent = styled.div`
	font-weight: 300;
	margin: 20px 0;
`;

export const AboutClinicMark = styled.div`
	font-weight: 500;
	margin: 5px 0;
	& a {
		font-weight: 300;
		text-decoration: none;
		color: #0c1f3f;
		transition: all 0.3s ease;
		&:hover {
			color: #424bec;
		}
	}

	& img {
		max-width: 100%;
		height: 40px;
		object-fit: contain;
		// background-color: #e5e5e5;
	}
`;
export const Plain = styled.div`
	font-weight: 300;
	text-decoration: none;
	color: #0c1f3f;
`;
export const AboutWrapper = styled.div`
	margin-bottom: 100px;
	@media (max-width: 475px) {
		margin-bottom: 41px;
	}
`;
export const Suburb = styled.div`
	margin: 10px 0;
	transition: all 0.3s ease;

	& a {
		font-weight: 300;
		color: rgb(12, 31, 63);
		text-decoration: none;
		&:hover {
			color: #0035cb;
			& > span {
				color: rgb(12, 31, 63);
			}
		}
	}
`;
export const Span = styled.span`
	display: inline-block;
	text-transform: capitalize;
	margin: 0 0 0 5px;
`;
export const ArrowWrapper = styled.div`
	padding-bottom: 25px;
	padding-left: 55px;
`;
export const ClinikoWrapper = styled.div`
	margin: 0 0 100px;
	padding-top: 30px;
	@media (max-width: 475px) {
		margin: 0 0 50px;
	}
`;
export const RequestRight = styled.div`
	margin-left: 50px;
	@media (max-width: 475px) {
		margin-left: 0;
		text-align: center;
	}
`;
export const TopBlockTitle = styled.div`
	padding-top: 100px;
	@media (max-width: 768px) {
		text-align: center;
	}
	@media (max-width: 425px) {
		padding-top: 59px;
	}
`;
export const TopBlockH5 = styled.h5`
	display: inline !important;
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	font-size: 34px;
	@media (max-width: 425px) {
		font-size: 20px;
	}
`;
export const TopBlockH1 = styled.h1`
	font-family: 'SharpGrotesk-MediumNo21' !important;
	font-size: 34px;
	color: #4643f5;
	display: inline;
	padding: 0 7px;
	@media (max-width: 768px) {
		text-align: center;
	}
	@media (max-width: 425px) {
		font-size: 20px;
	}
`;
export const RegisterWrapper = styled.div`
	@media (max-width: 525px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
const ImgBlock = styled.div`
	position: relative;
	max-width: 506px;
	width: 100%;
	padding: 0 0 29.5% 0;
	background-color: #f7f8f9;
	border-radius: 6px;
	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 6px;
	}
	@media (max-width: 800px) {
		padding: 0 0 64% 0;
		max-width: 100%;
	}
`;
export default clinicTemplate;
